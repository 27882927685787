<template>
	<v-container>
		<v-row>
			<v-col cols="6">
				<admin-default-header>Quizes</admin-default-header>
				<admin-default-description>
					Lorem, ipsum dolor sit amet consectetur adipisicing elit.
					Ipsam maiores vero quo nemo accusantium quae delectus a illo
					laudantium pariatur, soluta velit repellendus iusto deserunt
					perferendis odit, facere, magni veniam.
				</admin-default-description>
				<v-text-field
					v-model="searchText"
					class="search-course"
					placeholder="Start typing to Search"
					filled
					:loading="isLoading"
				></v-text-field>
			</v-col>
			<v-col cols="6"></v-col>
		</v-row>
		<v-tabs
			v-model="currentItem"
			ligth
			background-color="transparent"
			:slider-color="$store.getters.theme.mainAccentColor"
			slider-size="8"
			color="#000"
			class="organization-settings-tab-buttons-container"
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.component}`"
				class="organization-settings-tab-button primary-text"
				@click="currentItem = tab.component"
			>
				{{ tab.title }}
			</v-tab>
		</v-tabs>

		<v-tabs-items
			v-model="currentItem"
			class="organization-settings-tabs-container"
		>
			<v-tab-item
				v-for="tab in tabs"
				:key="tab.title"
				:value="tab.component"
				class="organization-settings-tab"
			>
				<keep-alive>
					<component
						:is="tab.component"
						:masterQuestions="displayedMasterQuestions"
						:myQuestions="displayedMyQuestions"
						@deleteQuestion="deleteQuestion"
						@duplicateQuestion="duplicateQuestion"
						@editQuestion="editQuestion"
					/>
				</keep-alive>
			</v-tab-item>
		</v-tabs-items>
	</v-container>
</template>

<script>
import { get } from "@/util/requests/get";
import { mapGetters } from "vuex";
import QuestionBankMasterBank from "./QuestionBankMasterBank.vue";
import QuestionBankMyQuestions from "./QuestionBankMyQuestions.vue";
import { post } from "@/util/requests/post";
import { remove } from "@/util/requests/remove";

export default {
	name: "QuestionBank",
	components: {
		QuestionBankMasterBank,
		QuestionBankMyQuestions
	},
	computed: {
		...mapGetters(["isLoading", "organization"]),
		displayedMyQuestions() {
			if (!!this.myQuestions === false) return;
			return this.myQuestions.filter(el =>
				String(el.questions[0]?.content).includes(this.searchText)
			);
		},
		displayedMasterQuestions() {
			if (!!this.masterQuestions === false) return;
			return this.masterQuestions.filter(el =>
				String(el.questions[0]?.content).includes(this.searchText)
			);
		}
	},
	data() {
		return {
			masterQuestions: null,
			myQuestions: null,
			searchText: "",
			currentItem: "",
			tabs: [
				{ title: "Master Bank", component: "QuestionBankMasterBank" },
				{ title: "My Questions", component: "QuestionBankMyQuestions" }
			]
		};
	},
	methods: {
		async getMasterQuestion() {
			await this.$store.dispatch("setIsLoading", true);
			try {
				const {
					data: { data }
				} = await get("/org_bank_questions");
				this.masterQuestions = data;
				this.myQuestions = data;
			} catch (error) {
				console.error(error);
				await this.$store.dispatch("setIsLoading", false);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async deleteQuestion(id) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				await remove(`/org_bank_question/${id}`);
				await this.getMasterQuestion();
			} catch (error) {
				console.log(error);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async duplicateQuestion(id) {
			const questionFromBank = this.masterQuestions.find(
				el => Number(el.id) === Number(id)
			);
			try {
				const {
					data: { data: question }
				} = await get(`/question/${questionFromBank.question_id}`);
				const {
					data: { data: newQuestion }
				} = await post("/question", {
					category_id: 1,
					content: "What's your fav colour?",
					custom: 0,
					help_html: "<h2>help</h2>",
					help_text: "help",
					label: "question",
					organization_id: 3,
					required: 0,
					sort_order: 0,
					type: "question"
				});
				await this.$store.dispatch("setIsLoading", true);
				const {
					data: { data }
				} = await post("/org_bank_question", {
					organization_id: this.organization.id,
					category_id: 1,
					question_id: newQuestion.id
				});
				await this.getMasterQuestion();
			} catch (error) {
				console.log(error);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async editQuestion(id) {
			const questionFromBank = this.masterQuestions.find(
				el => Number(el.id) === Number(id)
			);
			this.$router.push(
				`/admin/new-quiz/edit-question/${questionFromBank.question_id}?edit=true&from=${this.$route.name}`
			);
		}
	},
	async created() {
		await this.getMasterQuestion();
	}
};
</script>

<style></style>
